import React, { useCallback, useEffect, useState, memo } from 'react';
import TopBar from './TopBar';
import { useRouter } from 'next/router';
import { useShopBySlug, useShops, useViewerShops } from '../../api';
import ShopAdminBottomBar from '../../components/navigation/ShopAdminBottomBar';
import HomeOutlinedIcon from '@/img/home-outlined.svg';
import HomeFilledIcon from '@/img/home-filled.svg';
import CartIcon from '@/img/shopping-cart_[special].svg';
import CartFilledIcon from '@/img/shopping-cart_[special]_filled.svg';
import AllLives from './AllLives';
import { Box, createTheme, Stack, styled, ThemeProvider } from '@mui/material';
import { colorTokens } from '../../theme/color-tokens';
import { Paragraph } from '../../theme/text-variants';
import HostDetailsCard from '../../components/shops/HostDetailsCard';
import StoreDetailsCard from '../../components/shops/StoreDetailsCard';
import { OptionTabs } from '../Tabs/OptionTabs';
import { storePreviousRouteDetails } from '../../../store/reducers/app.reducer';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import ProgressSpinner from '../../components/ProgressSpinner';
import { colors } from '../../theme/colors';
import InfoIcon from '../../img/icons/info 2.svg';
import OrdersOutlinedIcon from '@/img/shop-outlined.svg';
import OrdersFilledIcon from '@/img/orders-filled.svg';

import CongratulationsIcon from '../../img/icons/Congratulations_small.svg';

const SHOPS_PER_PAGE = 10;
const dummyCategories = [
  { name: 'Fashion' },
  { name: 'Electronics' },
  { name: 'Home & Garden' },
  { name: 'Beauty' },
  { name: 'Sports' },
  { name: 'Books' },
  { name: 'Toys' },
  { name: 'Jewelry' },
  { name: 'Automotive' },
  { name: 'Food' },
  { name: 'Automotive' }
];

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '38px !important'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '38px',
          fontSize: '14px !important'
        }
      }
    }
  }
});

const MemoizedAllLives = memo(AllLives);
const MemoizedStoreDetailsCard = memo(StoreDetailsCard);
const MemoizedHostDetailsCard = memo(HostDetailsCard);

const HomePagePostSignIn = () => {
  const { pathname, query, push } = useRouter();
  const { shop } = useShopBySlug(query.shopSlug as string);
  const dispatch = useDispatch();
  const { previousRoute, previousTab } = useSelector((state: RootState) => state.app);
  const [selectedTab, setSelectedTab] = useState<number>(previousTab || 0);
  const { ref: loadMoreRef, inView } = useInView();

  const { shops, loading, hasMoreShops, fetchMoreShops, fetchingMoreShops } = useShops({
    variables: { first: SHOPS_PER_PAGE }
  });

  const loadMoreItems = useCallback(async () => {
    if (!loading && !fetchingMoreShops && hasMoreShops) {
      await fetchMoreShops();
    }
  }, [loading, fetchingMoreShops, hasMoreShops, fetchMoreShops]);

  useEffect(() => {
    if (inView && hasMoreShops) {
      loadMoreItems();
    }
  }, [inView, hasMoreShops, loadMoreItems]);

  useEffect(() => {
    dispatch(
      storePreviousRouteDetails({
        previousRoute: `/${shop?.slug}`,
        previousTab: selectedTab
      })
    );
  }, [shop, selectedTab, dispatch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const navItems = [
    {
      label: 'Home',
      icon: pathname === '/' ? <HomeFilledIcon /> : <HomeOutlinedIcon />,
      url: '/',
      active: pathname === '/'
    },
    {
      label: 'Cart',
      icon: pathname === '/cart' ? <CartFilledIcon /> : <CartIcon />,
      url: '/buyer/carts',
      active: pathname === '/cart'
    },
    {
      label: 'Orders',
      icon: pathname === '/orders' ? <OrdersFilledIcon /> : <OrdersOutlinedIcon />,
      url: '/buyer/orders',
      active: pathname === '/orders'
    }
  ];


  return (
    <ThemeProvider theme={theme}>
      <Box position={'fixed'} width={'100vw'} zIndex={'4000'}>
        <TopBarWrapper>
          <TopBar title="Home" />
          <TabsHeader>
            <OptionTabs value={selectedTab} onChange={handleTabChange} tabs={['Lives', 'Stores', 'Hosts']} />

            {selectedTab === 0 && (
              <HeaderBox>
                <IconWrapper>
                  <CongratulationsIcon />
                  <Box ml={2} mr={2}>
                    <HeaderText size="sm">Welcome to Jatango Live Shopping!</HeaderText>
                  </Box>
                  <MirroredIcon />
                </IconWrapper>
              </HeaderBox>
            )}
            {selectedTab === 1 && (
              <HeaderBox>
                <IconWrapper>
                  <HeaderText size="sm">Connect with stores for products you can sell</HeaderText>
                  <InfoIcon />
                </IconWrapper>
              </HeaderBox>
            )}
            {selectedTab === 2 && (
              <HeaderBox>
                <IconWrapper>
                  <HeaderText size="sm">Invite a host to sell your products</HeaderText>
                  <InfoIcon />
                </IconWrapper>
              </HeaderBox>
            )}
          </TabsHeader>
        </TopBarWrapper>
      </Box>

      <PageContainer>
        <ContentContainer selectedTab={selectedTab}>
          {selectedTab === 0 ? (
            <>
              <TabContent>
                <Stack width="100%">
                  <MemoizedAllLives stickyMuteButton />
                </Stack>
              </TabContent>
            </>
          ) : selectedTab === 1 ? (
            <TabContent>
              {shops.map((shop) => (
                <MemoizedStoreDetailsCard
                  key={shop.id}
                  storeName={shop.name}
                  categories={shop.shopCategories?.nodes?.map((node) => node.category?.name) || []}
                  commissionRate={shop.defaultCommission || 0}
                  profileImage={shop.shopCustomizations?.[0]?.shopProfileImage?.originalSrc}
                  onClick={() => push(`/${shop.slug}/store-profile`)}
                />
              ))}
              {hasMoreShops && <LoaderBox ref={loadMoreRef}>{fetchingMoreShops && <ProgressSpinner />}</LoaderBox>}
            </TabContent>
          ) : (
            <TabContent>
              {shops
                .filter((shop) => shop.shopUsers?.nodes?.[0]?.user?.name)
                .filter((shop) => shop.shopCategories?.nodes && shop.shopCategories?.nodes?.length > 0)
                .map((shop) => (
                  <MemoizedHostDetailsCard
                    key={shop.id}
                    followersCount={1.2}
                    hostName={shop.shopUsers?.nodes?.[0]?.user?.name || ''}
                    livesCount={shop?.shows?.nodes?.length || 0}
                    ordersCount={shop?.shopOrders?.nodes?.length || 0}
                    categories={shop.shopCategories?.nodes?.map((node) => node.category?.name) || []}
                    profileImage={shop.shopCustomizations?.[0]?.shopProfileImage?.originalSrc}
                    onClick={() => push(`/${shop.slug}/host-profile`)}
                  />
                ))}
              {hasMoreShops && <LoaderBox ref={loadMoreRef}>{fetchingMoreShops && <ProgressSpinner />}</LoaderBox>}
            </TabContent>
          )}
        </ContentContainer>
      </PageContainer>

      <ShopAdminBottomBar activeIndex={0} navItems={navItems} />
    </ThemeProvider>
  );
};

const TopBarWrapper = styled(Box)`
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 4000;
  background-color: ${colorTokens.backgroundScreen};
  border-bottom: 1px solid ${colorTokens.borderDefault};
`;

const PageContainer = styled(Box)({
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
});

const ContentContainer = styled(Box)<{ selectedTab: number }>(({ selectedTab }) => ({
  flex: 1,
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  paddingBottom: '88px',
  marginTop: '110px'
}));

const TabsHeader = styled(Box)({
  position: 'absolute',
  top: '40px',
  left: 0,
  right: 0,
  zIndex: 3000,
  backgroundColor: 'white',
  borderBottom: `1px solid ${colors.gray200}`
});

const HeaderBox = styled(Box)({
  zIndex: 10,
  backgroundColor: '#D21FFF',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  width: '100%'
});

const TabContent = styled(Stack)({
  padding: '16px',
  paddingBottom: '88px',
  gap: '8px'
});

const IconWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: '4px 16px',
  textAlign: 'center'
});

const MirroredIcon = styled(CongratulationsIcon)({
  transform: 'scaleX(-1)'
});

const HeaderText = styled(Paragraph)({
  fontWeight: 'bold',
  color: colorTokens.textOnImage
});

const LoaderBox = styled(Box)({
  padding: '16px 0',
  textAlign: 'center'
});
export default memo(HomePagePostSignIn);
